/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import React from 'react';

import seadooImage from '../assets/types/type_seadoo.png';
import pontoonImage from '../assets/types/type_pontoon.png';
import yachtImage from '../assets/types/type_yacht.png';
import fishingImage from '../assets/types/type_fishing.png';
import sportImage from '../assets/types/type_kayak.png';

export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'category',
    label: 'Type',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'powerboats-pontoons-ribs',
          label: 'Powerboats, Pontoons & RIBs',
          highlighted: true,
          imageSrc: pontoonImage,
        },
        {
          key: 'sailing',
          label: 'Sailing',
        },
        {
          key: 'yachts',
          label: 'Luxury Motor Yachts & Big Groups',
          highlighted: true,
          imageSrc: yachtImage,
        },
        {
          key: 'fishing',
          label: 'Fishing',
          highlighted: true,
          imageSrc: fishingImage,
        },
        {
          key: 'jetskis-waterskis-flyboards',
          label: 'Jetskis, waterskis & flyboards',
          highlighted: true,
          imageSrc: seadooImage,
        },
        {
          key: 'houseboat-overnighters',
          label: 'Houseboat & Overnighters',
        },
        {
          key: 'tours-lessons-diving',
          label: 'Tours, Lessons & Diving',
        },
        {
          key: 'boards-paddles',
          label: 'Boards, Canoes & Kayakes',
          highlighted: true,
          imageSrc: sportImage,
        },
        {
          key: 'ferries-taxis',
          label: 'Ferries & Taxis',
        },
        {
          key: 'electric',
          label: 'Electric',
        },
      ],
    },
  },
  {
    id: 'amenities',
    label: 'Amenities',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'partner',
          label: 'A WR Partner Boat',
        },
        {
          key: 'life_jackets',
          label: 'Life Jackets & Safety Gear',
        },
        {
          key: 'toilet',
          label: 'Toilet',
        },
        {
          key: 'bar',
          label: 'Bar',
        },
        {
          key: 'fishing_gear',
          label: 'Fishing Gear',
        },
        {
          key: 'watersports_equipment',
          label: 'Watersports Equipment',
        },
        {
          key: 'trailer',
          label: 'Trailer Included',
        },
        {
          key: 'guide',
          label: 'This Is A Guided Tour',
        },
      ],
    },
  },
  {
    id: 'captain',
    label: 'Include Captain?',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_captain'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'Yes',
          label: 'Yes',
        },
        {
          key: 'No',
          label: 'No',
        },
        {
          key: 'Renters Choice',
          label: 'Renters Choice',
        },
      ],
    },
  },
  {
    id: 'cancel',
    label: 'Cancelation Policy',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_cancel'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'Flexible - Totally Flexible',
          label: 'Flexible - Totally Flexible',
        },
        {
          key: 'Moderate - No refund within 7 Days',
          label: 'Moderate - No refund within 7 Days',
        },
        {
          key: 'Strict - No refund within 14 Days',
          label: 'Strict - No refund within 14 Days',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  // The default option for sorting
  defaultOption: 'pub_promote',

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },
    { key: 'pub_promote', label: 'Promoted' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: 'Relevance',
      longLabel: 'Relevance (Keyword search)',
    },
  ],
};

export const heroPopularSearches = [
  {
    key: 'ottawa',
    label: 'Ottawa',
    searchQuery:
      '?address=Ottawa%2C%20ON&bounds=45.82848551%2C-75.23440389%2C44.60978038%2C-76.63572142',
  },
  {
    key: 'vancouver',
    label: 'Vancouver',
    searchQuery:
      '?address=Vancouver%2C%20British%20Columbia%2C%20Canada&bounds=51.13145411%2C-121.35333685%2C47.46199817%2C-125.28063657',
  },
  {
    key: 'gta',
    label: 'GTA',
    searchQuery:
      '?address=Toronto%2C%20Ontario%2C%20Canada&bounds=46.08830369%2C-76.78574639%2C41.49422172%2C-81.22829429',
  },
  {
    key: 'vernon',
    label: 'Vernon',
    searchQuery:
      '?address=Vernon%2C%20British%20Columbia%2C%20Canada&bounds=50.71658314%2C-118.71448857%2C49.7487921%2C-119.939882',
  },
  {
    key: 'winnipeg',
    label: 'Winnipeg',
    searchQuery:
      's?address=Winnipeg%2C%20Manitoba%2C%20Canada&bounds=50.30088334%2C-96.58853039%2C49.40286726%2C-97.716584',
  },
];

export const durationOptions = [
  { key: '1', label: '1 day' },
  { key: '2', label: '2 days' },
  { key: '3', label: '3 days' },
  { key: '4', label: '4 days' },
  { key: '5', label: '5 days' },
  { key: '6', label: '6 days' },
  { key: '7', label: '7 days' },
  { key: '8', label: '8 days' },
  { key: '9', label: '9 days' },
  { key: '10', label: '10 days' },
  { key: '11', label: '11 days' },
  { key: '12', label: '12 days' },
  { key: '13', label: '13 days' },
  { key: '14', label: '14 days' },
  { key: '15', label: '15 days' },
];

export const dayPricingOptions = [
  { key: 'monday', label: 'Mon'},
  { key: 'tuesday', label: 'Tue'},
  { key: 'wednesday', label: 'Wed'},
  { key: 'thursday', label: 'Thu'},
  { key: 'friday', label: 'Fri'},
  { key: 'saturday', label: 'Sat'},
  { key: 'sunday', label: 'Sun'},
];

export const languagesConfig = [
  {
    key: 'ca',
    label: 'Canadian',
    domain: 'ca',
    customUrl: 'https://wetrentals.ca',
  },
  {
    key: 'au',
    label: 'Australian',
    domain: 'com.au',
    customUrl: 'https://wetrentals.com.au',
  },
  {
    key: 'eu',
    label: 'European',
    domain: 'eu',
    customUrl: 'https://www.wetrentals.eu',
  },
  {
    key: 'uk',
    label: 'UK',
    domain: 'uk',
    customUrl: 'https://www.wetrentals.uk',
  },
  {
    key: 'en',
    label: 'English',
    domain: 'com',
    customUrl: 'https://wetrentals.com',
  },
];

export const durationConfig = [
  { key: 'days', label: 'Day(s)' },
  { key: 'hours', label: 'Hour(s)' },
];
